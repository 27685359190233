import { graphql, navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import SEORevamp from "../components/common/SEO_Revamp"
import BlogModal from "../components/common/new-blog/BlogModal"
import Creative from "../components/new-blog-page/Creative"
import SearchBlog from "../components/new-blog-page/SearchBlogCategory"
import MainLayout from "../layouts/MainLayout"

const BlogCategoryTemplate = ({ data, pageContext }) => {
  const [searching, setSearching] = useState(false)
  const [filterCatagory, setFilterCatagory] = useState({})
  const [topicSearch, setTopicSearch] = useState("")

  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const length = 9

  const authorBlogPosts = data?.allStrapiBlogPost?.nodes
  const changeStrapiSchema = []
  authorBlogPosts?.map(post => {
    return changeStrapiSchema.push({
      featuredImage: post?.featuredImage,
      slug: post?.slug,
      isWP: false,
      title: post?.title,
      readingTime: post?.readingTime,
      categories: post?.categories,
      writer: post?.writer,
    })
  })

  const posts = [...changeStrapiSchema]
  const currentCategory = pageContext?.slug

  useEffect(() => {
    if (posts?.length > 0) {
      setList([...posts?.slice(0, length)])
    }
  }, [])

  useEffect(() => {
    let catagoryModel =
      filterCatagory?.selectedCategories &&
      Object.keys(filterCatagory?.selectedCategories).length > 0 &&
      Object.keys(filterCatagory?.selectedCategories)
    searching &&
      navigate(
        `/search/?${
          filterCatagory?.search ? `topic=${filterCatagory?.search}` : ""
        }${topicSearch ? `topic=${topicSearch}` : ""}${
          filterCatagory?.search && catagoryModel ? "&" : ""
        }${catagoryModel ? `categories=${catagoryModel}` : ""}`
      )
  }, [searching])

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < posts?.length
      const nextResults = isMore
        ? posts?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < posts?.length
    setHasMore(isMore)
  }, [list, posts])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  let headSchema = []
  const bodySchema = pageContext?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout bgChanged={false} schemas={bodySchema}>
      <section>
        <SearchBlog
          categories={data?.allStrapiCategory?.nodes}
          current={currentCategory}
          filterTopicData={list}
          searching={false}
          pageContext={pageContext}
        />
        {hasMore && (
          <Row className="mt-5">
            <Col className="d-flex justify-content-center">
              <div>
                <button className="btn_white2_border" onClick={handleLoadMore}>
                  Load more
                </button>
              </div>
            </Col>
          </Row>
        )}
      </section>
      <Creative />
      <BlogModal
        categories={data?.allStrapiCategory?.nodes}
        setFilterCatagory={setFilterCatagory}
        setSearching={setSearching}
        setTopicSearch={setTopicSearch}
      />
    </MainLayout>
  )
}

export const query = graphql`
  query getBlogsByCategory($slug: String) {
    allStrapiBlogPost(
      filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
      sort: { order: DESC, fields: publishedOn }
    ) {
      nodes {
        title
        slug
        readingTime
        createdAt(formatString: "DD MMMM, YYYY, hh:mm a")
        featuredImage {
          url
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        writer {
          name
          slug
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        categories {
          name
          slug
        }
      }
    }
    allStrapiCategory {
      nodes {
        name
        slug
      }
    }
  }
`

export default BlogCategoryTemplate

export const Head = ({ pageContext }) => {
  const seo = pageContext?.seo
  let headSchema = []
  const bodySchema = seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={seo?.metaTitle}
      description={seo?.metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/Blogs_29bc5a670c.webp"
    />
  )
}
