import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import InvoBlogs from "./InvoBlogs"
import * as styles from "./SearchBlog.module.scss"

const SearchBlog = ({ filterTopicData, current, pageContext }) => {
  const back = () => {
    navigate("/blog/")
  }
  return (
    <div>
      <Container>
        <React.Fragment>
          <div className={styles.topSection}>
            <div className={styles.back} onClick={back}>
              <StaticImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                alt="back"
                src={"../../images/new-blog/back.svg"}
              />{" "}
              <span>BACK</span>
            </div>
            <h1 className={styles.heading}>
              {current?.toUpperCase() || "INVOBLOG"}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: pageContext?.rawDesc }} />
          </div>
          <Row>
            {current && (
              <React.Fragment>
                {filterTopicData.length > 0 ? (
                  filterTopicData?.map((post, i) => (
                    <Col
                      key={i}
                      style={{ padding: 8, display: "flex" }}
                      md={6}
                      lg={4}
                      xl={4}
                    >
                      <InvoBlogs post={post} insights={true} />
                    </Col>
                  ))
                ) : (
                  <Col xs={12} className="text-center">
                    No Data
                  </Col>
                )}
              </React.Fragment>
            )}
          </Row>
        </React.Fragment>
      </Container>
    </div>
  )
}

export default SearchBlog
